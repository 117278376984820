import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Spinner from "../loading/spinner";
import AlreadyPatientBook from "./alreadyBookPatinet";
import NewPatientForm from "./newPatientForm";
import apiServices from "../../../Service/apiService";
import PatientList from "./patientList";

const VerifyPatientModal = ({ reset, onSubmit }) => {
  const [state, setState] = useState({
    showSpinner: false,
    newPatient: false,
    newPatientAbha: false,
    collectPatientData: [],
    collectPatientAbhaData :null,
    collectAbhaData: null,
    mobileContactNumber:""
  });

  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const closeNewPatientModal = () => {
    console.log("djh")
    toggleState("newPatient", false);
    toggleState("newPatientAbha", false);
    handleRemoveModal();
  };

  const handleRemoveModal = () => {
    reset();
  };



  const validationSchema = Yup.object()
    .shape({
      abhaNumber: Yup.string().matches(
        /^\d{14}$/,
        "ABHA number must be 14 digits"
      ),
      contact: Yup.string().matches(
        /^\d{10}$/,
        "Contact number must be 10 digits"
      ),
    })
    .test({
      name: "at-least-one-field",
      test: (values) => !!values.abhaNumber || !!values.contact,
      message: "Please enter either ABHA number or contact number",
    });
  const formik = useFormik({
    initialValues: {
      abhaNumber: "",
      contact: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async (values, { setErrors }) => {
      const errors = await formik.validateForm(); // Validate the form
      if (Object.keys(errors).length === 0) {
        // If there are no errors, continue with form submission
        if (values.abhaNumber || values.contact) {
          // If either ABHA number or contact number is provided, fetch data
          toggleState("collectAbhaData", values);
          fetchPatientData(values);
        } else {
          // If both ABHA number and contact number are empty, display an error
          setErrors({
            abhaNumber: "Please enter either ABHA number or contact number",
            contact: "Please enter either ABHA number or contact number",
          });
        }
      } else {
        // If there are errors, set them in Formik
        setErrors(errors);
      }
    },
  });

  const fetchPatientData = async (values) => {
    toggleState("showSpinner", true);

    if (values.abhaNumber !== "") {
      try {
        // Make API call for 'abhaNumber' route
        const response = await apiServices.get(
          "authentication",
          "/patient/getByAbhaNumber",
          null,
          {
            abhaNumber: values.abhaNumber,
          },
          null
        );
        if (response) {
          toggleState("collectPatientAbhaData", response.data);
          toggleState("newPatientAbha", true);
        }
      } catch (error) {
        if (error.response.data.statusCode === 404) {
          toggleState("newPatientAbha", true);
        }
        console.log("error occurred while fetching patient details", error);
      } finally {
        toggleState("showSpinner", false);
      }
    } else if (values.contact !== "") {
      try {
        // Make API call for 'contact' route
        const response = await apiServices.get(
          "authentication",
          "/patient/getByContact",
          null,
          {
            contact: values.contact,
          },
          null
        );
        if (response) {
          toggleState("collectPatientData", response);
          toggleState("newPatient", true);
        }
      } catch (error) {
        if (error.response.data.statusCode === 404) {
          toggleState("newPatient", true);
        }
      } finally {
        toggleState("showSpinner", false);
      }
    }
  };

  return (
    <>
      <div
        id="login-popup"
        tabIndex="-1"
        className={`bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex ${
          state.showSpinner ? "hidden" : ""
        }`}
      >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <button
              onClick={handleRemoveModal}
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  cliprule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>

            <div className="p-5">
              <p className="mb-4 text-sm font-normal text-gray-800"></p>
              <div className="text-center">
                <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
                  Book Your Appointment
                </p>
              </div>
              &nbsp;
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
                className="w-full"
              >
                <label for="Abha" className="sr-only">
                  ABHA Number
                </label>
                <input
                  type="number"
                  name="abhaNumber"
                  value={formik.values.abhaNumber}
                  onChange={formik.handleChange}
                  className="block w-full rounded-lg border border-gray-300 px-3 py-2  shadow-sm outline-none placeholder:text-gray-400 focus:ring-2 focus:ring-black focus:ring-offset-1"
                  placeholder="ABHA Number"
                />
                {formik.touched.abhaNumber && formik.errors.abhaNumber && (
                  <div className="text-red-500">{formik.errors.abhaNumber}</div>
                )}
                <div className="flex w-full items-center gap-2 py-3 text-sm text-slate-600">
                  <div className="h-px w-full bg-gray-500"></div>
                  OR
                  <div className="h-px w-full bg-gray-500"></div>
                </div>

                <label for="phone" className="sr-only">
                  Mobile Number
                </label>
                <input
                  type="number"
                  name="contact"
                  value={formik.values.contact}
                  onChange={formik.handleChange}
                  className="mt-2 block w-full rounded-lg border border-gray-300 px-3 mb-5 py-2 shadow-sm outline-none placeholder:text-gray-400 focus:ring-2 focus:ring-black focus:ring-offset-1"
                  placeholder="Mobile Number"
                />
                {formik.touched.contact && formik.errors.contact && (
                  <div className="text-red-500">{formik.errors.contact}</div>
                )}
                <button
                  type="submit"
                  className="inline-flex w-full items-center justify-center rounded-lg bg-black p-2 py-3  text-sm font-medium text-white outline-none focus:ring-2 focus:ring-black focus:ring-offset-1 disabled:bg-gray-400"
                >
                  Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Spinner */}
      {state.showSpinner && <Spinner />}

      {/* Appointment Form */}
      
      {state.newPatient &&  (
        <PatientList
          patientDetails={state.collectPatientData}
          responseData={state.collectAbhaData}
          reset={closeNewPatientModal}
        />
      )}
      {state.newPatientAbha &&  (
        <NewPatientForm
          patientDetails={state.collectPatientAbhaData}
          responseData={state.collectAbhaData}
          reset={closeNewPatientModal}
        />
      )}
    </>
  );
};

export default VerifyPatientModal;
